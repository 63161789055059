import { useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import { useRouter } from 'next/router'

import styles from '../styles/Nav.module.scss'
import Link from 'next/link'


const primaryNav = [
    { title: 'Projekte', link: '/projekte' },
    { title: 'Ventures', link: '/ventures' },
    { title: 'Über uns', link: '/ueber-uns' },
    { title: 'Karriere', link: '/karriere' },
    { title: 'Kontakt', link: '/kontakt' },
]

const secondaryNav = [
    { title: 'Linkedin', link: 'https://at.linkedin.com/company/tronic-innovation' },
]


function NavItem({ item, menu, toggleOpen, target }) {
    const router = useRouter()
    const isActive = router.asPath === item.link ? 'underline' : '';

    const variants = {
        open: {
            y: 0,
            opacity: 1,
            transition: {
                y: { stiffness: 1000, velocity: -100 }
            }
        },
        closed: {
            y: 0,
            opacity: 0,
            transition: {
                y: { stiffness: 1000, }
            }
        }
    };

    return (
        <motion.li variants={variants} className={menu === 'primary' ? styles.navItemPrimary : styles.navItemSecondary}>
            <Link prefetch={false} href={item.link}>
                <a className={isActive} target={target ?? '_self'} onClick={() => toggleOpen()}>{item.title}</a>
            </Link>
        </motion.li>
    )
}

function NavItemDesktop({ item, target }) {
    const router = useRouter()
    // const isActive = router.asPath === item.link ? 'underlineDesktop' : '';

    return (
        <li className={styles.navItemDesktop}>
            <Link prefetch={false} href={item.link}>
                <a target={target ?? '_self'}>{item.title}</a>
            </Link>
        </li>
    )
}

export function NavMobile({ isOpen, toggleOpen }) {

    const containerRef = useRef(null)
    const [deviceHeight, setDeviceHeight] = useState(0)

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setDeviceHeight(window.innerHeight)
        }
    }, [])

    const overlayVariants = {
        open: {
            y: 0,
            opacity: 1,
        },
        closed: {
            y: deviceHeight,
            display: 0
        }
    };

    const listVariants = {
        open: {
            transition: { staggerChildren: 0.05, delayChildren: 0.2 }
        },
        closed: {
            transition: { staggerChildren: 0.05 }
        }
    };

    return (
        <motion.nav
            animate={isOpen ? 'open' : 'closed'}
            ref={containerRef}
            variants={overlayVariants}
            className={styles.navOverlay}
            initial='closed'
            exit='closed'
            transition={{ type: 'linear' }}
            layout
        >
            <motion.ul variants={listVariants} className={styles.navList}>
                {primaryNav.map(item => (
                    <NavItem item={item} key={item.title} menu="primary" toggleOpen={toggleOpen} />
                ))}
                <li className={styles.spacer}></li>
                {secondaryNav.map(item => (
                    <NavItem item={item} key={item.title} menu="secondary" toggleOpen={toggleOpen} target="_blank" />
                ))}
            </motion.ul>
        </motion.nav>
    )
}

export function NavDesktop({ }) {
    return (
        <div className={styles.navDesktop}>
            <ul className={styles.navList}>
                {primaryNav.map(item => (
                    <NavItemDesktop item={item} key={item.title} />
                ))}
            </ul>
            <ul>
                {secondaryNav.map(item => (
                    <NavItemDesktop item={item} key={item.title} target="_blank" />
                ))}
            </ul>
        </div>
    )
}

