import Link from 'next/link'
import { motion, useCycle } from 'framer-motion'
import { useRouter } from 'next/router'
import Footer from './Footer'

import styles from '../styles/Layout.module.scss'
import { NavDesktop, NavMobile } from './Nav'
import { useEffect } from 'react'


function Layout({ children, page }) {
    const router = useRouter()

    const [isOpen, toggleOpen] = useCycle(false, true)

    return (
        <>
            <div className={styles.container}>
                <header className={styles.header}>
                    <div className={styles.logo}>
                        <Link prefetch={false} href='/'>
                            <a aria-label="Zur Startseite">
                                <svg viewBox="0 0 276 50">
                                    <g id="Group">
                                        <rect id="Rectangle-path" x="209.35" y="23.85" width="10.13" height="25.73"></rect>
                                        <polygon id="Shape" points="209.25 8.16 209.25 18.31 219.4 18.31 219.4 8.27 213.17 8.27 216.01 0 212.9 0"></polygon>
                                        <polygon id="Shape" points="28.55 15.67 28.55 49.58 18.94 49.58 18.94 15.67 0 15.67 0 8.29 47.58 8.29 47.58 15.67"></polygon>
                                        <path d="M51.33,49.58 L51.33,8.29 L86.65,8.29 C89.93,8.29 92.3066667,8.92 93.78,10.18 C95.2533333,11.44 95.99,13.4766667 95.99,16.29 L95.99,25.98 C95.99,28.7666667 95.2533333,30.7966667 93.78,32.07 C92.3066667,33.3433333 89.93,33.98 86.65,33.98 L81.26,33.98 L100.32,49.57 L86.53,49.57 L69.85,33.99 L60.75,33.99 L60.75,49.58 L51.33,49.58 Z M82.38,15.44 L60.78,15.44 L60.78,26.92 L82.38,26.92 C83.5855685,27.041858 84.7990292,26.7830797 85.85,26.18 C86.5359933,25.5148163 86.8756513,24.569681 86.77,23.62 L86.77,18.62 C86.8513382,17.708945 86.5126005,16.8105535 85.85,16.18 C84.8016524,15.5796055 83.5922193,15.3209406 82.39,15.44 L82.38,15.44 Z" id="Shape"></path>
                                        <path d="M100.62,18.05 C100.62,14.3033333 101.323333,11.7366667 102.73,10.35 C104.136667,8.96333333 106.846667,8.27333333 110.86,8.28 L141.25,8.28 C145.25,8.28 147.956667,8.97 149.37,10.35 C150.783333,11.73 151.486667,14.2966667 151.48,18.05 L151.48,39.81 C151.48,43.5833333 150.776667,46.1533333 149.37,47.52 C147.963333,48.8866667 145.256667,49.57 141.25,49.57 L110.85,49.57 C106.85,49.57 104.143333,48.8866667 102.73,47.52 C101.316667,46.1533333 100.613333,43.5833333 100.62,39.81 L100.62,18.05 Z M110.23,41.8 L141.91,41.8 L141.91,15.67 L110.23,15.67 L110.23,41.8 Z" id="Shape"></path>
                                        <path d="M155.63,49.58 L155.63,8.29 L162.19,8.29 L191.25,32.63 C192.236667,33.4366667 193.11,34.2 193.87,34.92 C194.603539,35.6146437 195.294778,36.3526339 195.94,37.13 C195.786667,34.81 195.683333,33.17 195.63,32.21 C195.576667,31.25 195.55,30.5066667 195.55,29.98 L195.55,8.29 L204.18,8.29 L204.18,49.58 L197.62,49.58 L167.31,24.07 C166.583333,23.4033333 165.946667,22.8166667 165.4,22.31 C164.866657,21.802659 164.359341,21.2686422 163.88,20.71 C164.006667,21.9833333 164.103333,23.1866667 164.17,24.32 C164.236667,25.4533333 164.27,26.4366667 164.27,27.27 L164.27,49.57 L155.63,49.58 Z" id="Shape"></path>
                                        <path d="M265.57,36.04 L265.57,41.8 L233.85,41.8 L233.85,15.67 L265.53,15.67 L265.53,21.82 L275.1,21.82 L275.1,18.05 C275.1,14.3033333 274.396667,11.7366667 272.99,10.35 C271.583333,8.96333333 268.876667,8.27333333 264.87,8.28 L234.52,8.28 C230.52,8.28 227.813333,8.97 226.4,10.35 C224.986667,11.73 224.283333,14.2966667 224.29,18.05 L224.29,39.81 C224.29,43.5833333 224.993333,46.1533333 226.4,47.52 C227.806667,48.8866667 230.516667,49.57 234.53,49.57 L264.92,49.57 C268.92,49.57 271.626667,48.8866667 273.04,47.52 C274.453333,46.1533333 275.156667,43.5833333 275.15,39.81 L275.15,36.04 L265.57,36.04 Z" id="Shape"></path>
                                    </g>
                                </svg>
                            </a>
                        </Link>
                    </div>
                    <div className={styles.nav}>
                        <NavDesktop />
                        <span onClick={() => toggleOpen()} className={[styles.mobileNavOpen, isOpen && styles.mobileNavIsOpen].join(' ')}>Menü</span>
                    </div>
                </header>
                <motion.div
                    key={router.route}
                    initial="initial"
                    animate="animate"
                    variants={{
                        initial: {
                            opacity: 0,
                        },
                        animate: {
                            opacity: 1,
                        },
                    }}
                >
                    <div className={[styles.content, 'content', page === 'home' ? styles.contentHome : null].join(' ')}>{children}</div>
                </motion.div>
                <Footer />
            </div>
            {isOpen && <NavMobile isOpen={isOpen} toggleOpen={toggleOpen} />}
        </>
    );
}

export default Layout