import { motion } from 'framer-motion'

export default function Reveal({ delay = 1, children, className, onClick, id }) {
    return (
        <motion.div
            initial={{ opacity: 0, translateY: 20 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            exit={{ opacity: 0, translateY: 20 }}
            viewport={{ once: true }}
            transition={{ delay: 0.1 * delay }}
            className={className}
            onClick={onClick}
            id={id}
        >
            {children}
        </motion.div>
    )
}