import Link from 'next/link'
import Image from 'next/image'

import styles from '../styles/Footer.module.scss'


export default function Footer() {

    const legalNav = [
        { title: 'Datenschutzerklärung', link: '/datenschutzerklaerung' },
        { title: 'AGB', link: '/agb' },
        { title: 'Impressum', link: '/impressum' },
    ]

    return (
        <>
            <footer className={styles.footerWrapper}>
                <div className={styles.colLeft}>
                    <ul className={styles.footerNav}>
                        {legalNav.map(item => (
                            <li key={item.title}>
                                <Link prefetch={false} href={item.link}>
                                    <a>{item.title}</a>
                                </Link>
                            </li>
                        ))}
                    </ul>
                    <div className={styles.copyright}>© {new Date().getFullYear()} TRONIC Innovation GmbH.<br />Alle Rechte vorbehalten.</div>
                </div>
                <div className={styles.colRight}>
                    <Image src="/img/shopify_partner_badge.svg" alt="TRONIC ist offizieller Shopify Partner" width="200" height="50" />
                </div>
            </footer>
        </>
    )
}